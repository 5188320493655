import {
  AntdLayout,
  Button,
  Card,
  Form,
  Typography,
  Input,
  Row,
  Col,
  Modal,
  useModalForm,
} from "@pankod/refine-antd";
import { useLogin, useNotification, useTranslate } from "@pankod/refine-core";
import { Link } from "@pankod/refine-react-router-v6";
import { emailPattern } from "components/helper";
import "./styles.css";
import { useClearStore } from "hooks";
import axios, { isAxiosError } from "axios";
import { IForgotPasswordModalForm } from "types";

export interface ILoginProps {
  email: string;
  password: string;
}

const { Title, Text } = Typography;

export const LoginPage = () => {
  const t = useTranslate();
  const [form] = Form.useForm<ILoginProps>();
  const { mutate: mutateClearStore } = useClearStore();
  const { mutate: login } = useLogin<ILoginProps>();
  const { show, close, formProps, modalProps } = useModalForm({
    action: "create",
  });
  const { open } = useNotification();

  const sendEmailMessage = async (email: string) => {
    try {
      const { status, data } = await axios.post(
        `${process.env.REACT_APP_API_URL}partner/forgot_password`,
        {
          email: email,
        }
      );
      if (status === 200) {
        open?.({
          type: "success",
          message: "На пошту було відправлено посилання на відновлення паролю",
          description: "Успіх",
        });
        close();
      } else {
        open?.({
          type: "error",
          message: "Спробуйте ще раз",
          description: "Виникла помилка",
        });
      }
    } catch (e) {
      if (isAxiosError(e)) {
        open?.({
          type: "error",
          message: e.response!.data.message,
          description: "Виникла помилка",
        });
      }
    }
  };
  return (
    <AntdLayout className="login-layout">
      <div className="header-bar">
        <div className="header-container">
          <img src={"/logo.svg"} alt="Логотип Офіс Центр" className="logo" />
          <div className="header-text">
            <h1>КАНЦТОВАРИ</h1>
            <p>Все для офісу, навчання та творчості</p>
          </div>
        </div>
      </div>

      <Row justify="space-around" align="middle" className="content-row">
        <Col className="col1">
          <Title level={3} className="login-title">
            Оптимальне рішення для вашого бізнесу!
          </Title>
          <p className="p1">
            Авторизуйтесь через особистий кабінет та отримайте доступ до
            широкого асортименту товарів, які задовольнять усі потреби вашої
            компанії.
          </p>
          <p className="p2">
            Ми цінуємо ваш час та зусилля, тому пропонуємо оптимальні рішення
            для ефективної роботи вашого бізнесу.
          </p>
        </Col>
        <Col className="col2">
          <div className="login-container">
            <Card className="login-card">
              <Title level={3} className="login-title">
                Авторизація
              </Title>
              <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                  mutateClearStore();
                  login(values as ILoginProps);
                }}
              >
                <Form.Item
                  name="email"
                  label={t("pages.login.fields.email")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.login.errors.validEmail"),
                    },
                    {
                      pattern: emailPattern,
                      message: t("pages.login.errors.validEmail"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("pages.login.fields.password")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.login.errors.validPassword"),
                    },
                    {
                      min: 8,
                      max: 32,
                      message: t("pages.login.errors.minMaxPassword"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Button type="primary" size="large" htmlType="submit" block>
                  Увійти
                </Button>
              </Form>
              <div className="login-footer">
                <Text>
                  Немає акаунту?{" "}
                  <Link to="/register" style={{ fontWeight: "bold" }}>
                    Зареєструватись
                  </Link>
                </Text>
                <br />
                <Text>
                  {t("pages.forgotPassword.title", "Втрачено пароль?")}
                  <Link
                    to="#"
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      show();
                    }}
                  >
                    {t("pages.login.buttons.forgotPassword", "Забув пароль")}
                  </Link>
                </Text>
              </div>
            </Card>
            <Modal
              {...modalProps}
              width="450px"
              title="Забув пароль"
              okText={"Відправити"}
              cancelText={"Відмінити"}
            >
              <Form
                {...formProps}
                onFinish={(data) => {
                  sendEmailMessage(
                    (data as IForgotPasswordModalForm).forgotPasswordEmail
                  );
                }}
              >
                <Form.Item
                  name="forgotPasswordEmail"
                  label={t("pages.login.fields.email")}
                  rules={[
                    {
                      required: true,
                      message: t("pages.login.errors.validEmail"),
                    },
                    {
                      pattern: emailPattern,
                      message: t("pages.login.errors.validEmail"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
